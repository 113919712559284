import { Profiler, useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { logProfiler } from 'src/common/logProfiler'
import { Header } from 'src/components/Header'
import { PageContainer } from 'src/screens/PageContainer'
import {
  selectCanManagePeers,
  selectIsAssignable,
  selectIsLoggedIn,
  selectUserId,
} from 'src/store/selectors/auth'
import { clearSelectedChannel } from 'src/store/slices/messaging'
import { useAppDispatch } from 'src/store/useAppDispatch'
import { useAppSelector } from 'src/store/useAppSelector'
import { BaseLink } from 'src/types'

export const PeerPortalLayout = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const canManagePeers = useAppSelector(selectCanManagePeers)
  const isAssignable = useAppSelector(selectIsAssignable)
  const userId = useAppSelector(selectUserId)

  const navLinks: BaseLink[] = [
    { link: '/peer-portal/members', label: 'Members' },
    { link: '/peer-portal/messages', label: 'Messages' },
    ...(canManagePeers
      ? [
          { link: '/peer-portal/peers', label: 'Peers' },
          { link: '/peer-portal/deleted-messages', label: 'Deleted Messages' },
        ]
      : []),
    ...(isAssignable
      ? [{ link: `/peer-portal/peers/${userId}`, label: 'Profile' }]
      : []),
  ]

  useEffect(() => {
    return () => {
      dispatch(clearSelectedChannel())
    }
  }, [dispatch])

  if (!isLoggedIn) return <Navigate state={{ from: location }} to="/login" />

  return (
    <Profiler id="PeerPortal" onRender={logProfiler}>
      <Header dashboardMode="peerPortal" navLinks={navLinks} />
      <PageContainer>
        <Outlet />
      </PageContainer>
    </Profiler>
  )
}
