import { MigrationManifest, PersistedState } from 'redux-persist'

export const migrations: MigrationManifest = {
  0: ((
    state:
      | (PersistedState & { peerPortal: { members: { columns: object } } })
      | undefined,
  ) => {
    if (!state) return state

    return {
      ...state,
      members: {
        ...state.peerPortal.members,
        columns: { ...state.peerPortal.members.columns, language: true },
      },
    }
  }) as (state: PersistedState) => PersistedState,
  1: ((
    state:
      | (PersistedState & { peerPortal: { members: { columns: object } } })
      | undefined,
  ) => {
    if (!state) return state

    return {
      ...state,
      members: {
        ...state.peerPortal.members,
        columns: {
          ...state.peerPortal.members.columns,
          lastMessageSentBy: true,
        },
      },
    }
  }) as (state: PersistedState) => PersistedState,
  2: ((
    state:
      | (PersistedState & { peerPortal: { members: { columns: object } } })
      | undefined,
  ) => {
    if (!state) return state

    return {
      ...state,
      members: {
        ...state.peerPortal.members,
        columns: {
          ...state.peerPortal.members.columns,
          lastOutreach: true,
        },
      },
    }
  }) as (state: PersistedState) => PersistedState,
}
