import DoneAllIcon from '@mui/icons-material/DoneAll'
import { Stack } from '@mui/material'
import MessageContent, {
  MessageContentProps,
} from '@sendbird/uikit-react/ui/MessageContent'

export const Message = (props: MessageContentProps) => {
  const { message, channel } = props
  if (!message.isUserMessage()) return <></>

  const readMembers = channel?.getReadMembers(message)
  const isMemberRead = readMembers?.find(({ userId }) =>
    userId.includes('app_'),
  )

  return (
    <>
      <MessageContent {...props} />
      {isMemberRead && (
        <Stack flexDirection="row" justifyContent="flex-end">
          <DoneAllIcon
            aria-label="Read"
            color="success"
            sx={t => ({ fontSize: 16, mt: t.spacing(-2), mb: t.spacing(2) })}
          />
        </Stack>
      )}
    </>
  )
}
