import {
  addBreadcrumb,
  captureEvent,
  Event,
  SeverityLevel,
} from '@sentry/react'

import { isDev } from './isDev'

const Severity = {
  Error: 'error' as SeverityLevel,
  Warning: 'warning' as SeverityLevel,
  Info: 'info' as SeverityLevel,
}

const logToSentry = (
  level: SeverityLevel,
  title: string,
  details?: unknown,
) => {
  if (isDev()) {
    /* eslint-disable no-console */
    const optionalArgs = details ? [details] : []
    if (level === Severity.Warning) {
      console.warn(title, ...optionalArgs)
    } else if (level === Severity.Info) {
      console.log(title, ...optionalArgs)
    } else {
      console.error(title, ...optionalArgs)
    }
    /* eslint-enable no-console */
    return
  }

  addBreadcrumb({
    category: `Log ${level}`,
    data: { title, details },
  })

  try {
    const sentryData: Event = { message: title, level }
    if (details) {
      sentryData.extra = { logDetails: JSON.stringify(details) }
    }
    captureEvent(sentryData)
  } catch (e) {
    console.error('Could not log to Sentry', title, details, e)
  }
}

export const logError = (title: string, details?: unknown) => {
  logToSentry(Severity.Error, title, details)
}

export const logWarning = (title: string, details?: unknown) => {
  logToSentry(Severity.Warning, title, details)
}

export const logInfo = (title: string, details?: unknown) => {
  logToSentry(Severity.Info, title, details)
}
