import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { App } from 'src/App'
import { logInfo } from 'src/common/logger'

if (import.meta.env.VITE_SENTRY_DSN && import.meta.env.VITE_WECONNECT_ENV) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_WECONNECT_ENV,
    release: import.meta.env.VITE_RELEASE_ID,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.replayIntegration({ maskAllText: true, blockAllMedia: true }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
  })
}

navigator.serviceWorker?.getRegistrations().then(registrations => {
  for (const registration of registrations) {
    registration.unregister()
    logInfo('unregistered zombie dashboard service worker')
  }
})

createRoot(document.getElementById('root')!).render(<App />)
