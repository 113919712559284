import { SortDirection } from '@mui/material'
import qs from 'qs'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Order,
  QueryByField,
  QueryMembersOrderBy,
  RewardsCodesOrderBy,
} from 'src/gql/graphql'

type OrderByEnum =
  | RewardsCodesOrderBy
  | QueryMembersOrderBy
  | RewardsCodesOrderBy
  | string

export interface BaseSearchParams<T = OrderByEnum> {
  [k: string]:
    | string
    | undefined
    | SortDirection
    | number
    | boolean
    | T
    | object
  rowsPerPage?: string | number
  page?: string | number
  order?: SortDirection
  orderBy?: T
  searchTerm?: string
}

export interface MemberSearchParams
  extends BaseSearchParams<QueryMembersOrderBy> {
  contractId?: string
  treatmentProviderId?: string
  memberType?: string
  engaged?: boolean
  starred?: boolean
  queryByFields?: QueryByField
}

export const useSearchParams = <
  T extends BaseSearchParams = BaseSearchParams,
>() => {
  const navigate = useNavigate()
  const location = useLocation()

  const params = (qs.parse(location.search.substring(1)) ?? {}) as T

  const handleChangeSearchParams = (changedParams: Partial<T>): void => {
    const newParams = { ...params, ...changedParams }

    if (changedParams?.searchTerm !== params.searchTerm) delete params.page

    navigate(`${location.pathname}?${qs.stringify(newParams)}`, {
      replace: true,
    })
  }

  const handleSort = (clicked: OrderByEnum) =>
    handleChangeSearchParams({
      orderBy: clicked,
      order:
        params.orderBy === clicked && params.order === Order.Asc
          ? Order.Desc
          : Order.Asc,
      page: 1,
    } as T)

  return { params, handleChangeSearchParams, handleSort }
}
