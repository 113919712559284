/* eslint-disable no-console */
export const logProfiler = (
  id: string,
  phase: 'mount' | 'update' | 'nested-update',
  actualDuration: number,
  baseDuration: number,
  startTime: number,
  commitTime: number,
) => {
  if (!import.meta.env.PROFILE) return
  console.log(`${id}'s ${phase} phase:`)
  console.log(`Actual time: ${actualDuration}`)
  console.log(`Base time: ${baseDuration}`)
  console.log(`Start time: ${startTime}`)
  console.log(`Commit time: ${commitTime}`)
}
