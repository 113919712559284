import { GroupChannel } from '@sendbird/uikit-react/GroupChannel'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ConversationHeader } from 'src/components/ConversationHeader'
import { Message } from 'src/components/Message'
import { MessageInput } from 'src/components/MessageInput'
import { selectSelectedChannelUrl } from 'src/store/selectors/messaging'
import { setSelectedChannel } from 'src/store/slices/messaging'
import { useAppDispatch } from 'src/store/useAppDispatch'
import { useAppSelector } from 'src/store/useAppSelector'

export const Conversation = () => {
  const params = useParams()
  const dispatch = useAppDispatch()
  const channelUrl = useAppSelector(selectSelectedChannelUrl)

  useEffect(() => {
    if (params.channelUrl) dispatch(setSelectedChannel(params.channelUrl))
  }, [params.channelUrl, dispatch])

  if (!channelUrl) return null

  return (
    <GroupChannel
      channelUrl={channelUrl}
      replyType="NONE"
      messageListQueryParams={{
        prevResultLimit: 30,
        nextResultLimit: 30,
      }}
      disableMarkAsRead={false}
      renderMessageContent={Message}
      renderChannelHeader={() => <ConversationHeader />}
      renderMessageInput={() => <MessageInput />}
    />
  )
}
